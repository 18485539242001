export default {
  getCurrentSemesterUserForAUser: '/semesterUser/getCurrentSemesterUserForAUser',
  getSemesterUsersForAUser: '/semesterUser/getSemesterUsersForAUser',
  getSemesterUsersOfASubjectOfADivision: '/semesterUser/getSemesterUsersOfASubjectOfADivision',
  getSemesterUsersOfASubjectOfAGroup: '/semesterUser/getSemesterUsersOfASubjectOfAGroup',
  getFacultiesOfAInstitute: '/semesterUser/getFacultiesOfAInstitute',
  getStudentsOfADivision: '/semesterUser/getStudentsOfADivision',
  getSemesterUsersOfASubject: '/semesterUser/getSemesterUsersOfASubject',
  getMultipleSemesterUsers: '/semesterUser/getMultipleSemesterUsers',
  getStudentsOfACourseYear: '/semesterUser/getStudentsOfACourseYear',
  getSemUserWithNameForAttendanceAnalysis: '/semesterUser/getSemUserWithNameForAttendanceAnalysis'
}
