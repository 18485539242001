<template src='./activityCumulativeAttendance.html'></template>
<script>
import LectureRepository from '../../Repository/lectures'
import UserRepository from '../../Repository/User'
import SemesterUserRepository from '../../Repository/SemesterUser'
import SubjectRepository from '../../Repository/Subjects'
import SemesterRepository from '../../Repository/Semester'
import { convertUTCDateToLocalDate } from "../../Services/Utils/DateTimeFormatter";
import { createExcelThroughJson, generateJsonFromExcelWithoutSchema, createMultiSheetStyledExcel } from "../../utils/excel";
export default {
  name: 'activityAttendanceAnalysis',
  data() {
    return {
      allUserObject: {},
      selectedLecForTable: [],
      overallForAllLec: [
        {
          text: 'Date',
          value: 'startTime'
        },
        {
          text: 'Time',
          value: 'time'
        },
        {
          text: 'Status',
          value: 'status'
        }
      ],
      allLecItem: [],
      showAllLecDialog: false,
      today: '',
      defaultLimitDialog: false,
      totalDefaulters: 0,
      defaulterLimitDummy: 0,
      defaulterLimit: 0,
      flagForShow: true,
      loaderForTable: false,
      addedLecInfo: null,
      updatedLecCount: null,
      allExcelObject: {},
      allocatedStudentObject: {},
      maxColumnCount: 0,
      maxRowCount: 0,
      errorDialogForBackdatedLec: false,
      selectedDataForBackdated: {},
      backdatedFile: null,
      errorForBackdatedExcel: [],
      dialogForBackdated: false,
      allUserData: [],
      allLec: [],
      overallData: [],
      overallHeaders: [
        {
          text: "Department",
          value: "department",
          width: '100px'
        },
        {
          text: "Course Year",
          value: "courseYear",
          width: '100px'
        },
        {
          text: "Course",
          value: "subjectName",
          width: '200px'
        },
        {
          text: "Allocation",
          value: "allocation",
          width: '200px'
        },
        {
          text: "Total Lectures Conducted",
          value: "conductedCount"
        },
        {
          text: "Total Lectures Not Conducted",
          value: "notConductedCount"
        },
        {
          text: "Total Lectures Cancelled",
          value: "cancelCount"
        },
        {
          text: "Total Students Allocated",
          value: "allocatedStudentCount"
        },
        {
          text: "Overall Attendance (%)",
          value: "overallAttendance"
        }
      ],
      headerForAllocation: [
        {
          text: "Name",
          value: "fullName",
          width: '200px'
        },
        {
          text: "Roll No",
          value: "rollNumber",
          width: '150px'
        },
        {
          text: "Mentor Name",
          value: "mentor",
          width: '200px'
        },
        {
          text: "PRN",
          value: "collagePRNNo",
          width: '150px'
        }
      ],
      headerForAllocationDummy: [
        {
          text: "Name",
          value: "fullName",
          width: '200px'
        },
        {
          text: "Roll No",
          value: "rollNumber",
          width: '150px'
        },
        {
          text: "Mentor Name",
          value: "mentor",
          width: '200px'
        },
        {
          text: "PRN",
          value: "collagePRNNo",
          width: '150px'
        }
      ],
      studentData: [],
      searchValue: '',
      tab: '',
      tabNames: [],
      allocationDetails: [],
      menu: false,
      startDate: '',
      menu1: false,
      endDate: '',
      subjectIdsAllocated: [],
      userName: '',
      selectedSubjectId: '',
      overallViewFlag: true,
      instituteName: '',
      selectedInstitute: '',
      selectedSemester: {},
      isMobileView: false,
      userData: {}
    }
  },
  created() {
    this.instituteName = this.$store.getters['instituteData/get_instituteName']
    this.$store.commit('liveData/set_selectedActivityName', 'Attendance Dashboard')
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.isMobileView = this.$store.getters['liveData/get_isMobileView']
    this.userData = this.$store.getters['user/get_userData']
    this.lectureRepositoryInstance = new LectureRepository(this)
    this.SemesterRepositoryInstance = new SemesterRepository(this)
    this.userRepositoryInstance = new UserRepository(this)
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    this.subjectRepositoryInstance = new SubjectRepository(this)
    this.fetchData()
    this.userName = this.$store.getters['user/get_userName']
  },
  methods: {
    addLecInTable() {
      this.selectedDataForBackdated.selectedLecForTable = [...this.selectedLecForTable]
      if (this.selectedDataForBackdated.selectedLecForTable.length > 0) {
        this.selectedDataForBackdated.selectedLecForTable.sort((a, b) => {
          return new Date(a.startTime) - new Date(b.startTime);
        });
      }
      this.prepareTableDataForEachAllocation(this.selectedDataForBackdated, 'selectedLecForTable')
      this.tabChanged()
    },
    getDate(date) {
      return convertUTCDateToLocalDate(date)
    },
    showAllLecDialogOpen(data) {
      this.showAllLecDialog = true
      this.selectedDataForBackdated = data
      this.allLecItem = [...this.selectedDataForBackdated.conductedLecArr, ...this.selectedDataForBackdated.notConductedLecArr, ...this.selectedDataForBackdated.cancelLecArr]
      this.selectedLecForTable = [...this.selectedDataForBackdated.conductedLecArr]
      if (Array.isArray(this.selectedDataForBackdated.selectedLecForTable) && this.selectedDataForBackdated.selectedLecForTable.length > 0) {
        this.selectedLecForTable = [...this.selectedDataForBackdated.selectedLecForTable]
      }
    },
    rowClass(item) {
      if (Math.round(Number(item.overallAttendance)) > Number(this.defaulterLimit)) {
        return 'nonDefaulter'
      } else {
        return 'defaulterBackgroundColor'
      }
    },
    applyDefaultLimit() {
      this.defaulterLimit = this.defaulterLimitDummy
      this.totalDefaulters = 0
      if (this.tab !== 0 && this.studentData.length > 0) {
        this.studentData.forEach((stu) => {
          if (Math.round(Number(stu.overallAttendance)) < Number(this.defaulterLimit)) {
            this.totalDefaulters++;
          }
        })
      }
    },
    async endDateChanged() {
      this.flagForShow = false
      await this.fetchData()
      this.tabChanged()
      this.flagForShow = true
    },
    checkBothDateAndTimePresent(data, arr, type) {
      let flagForTime = false
      if (type === 'checkTime') {
        arr.forEach((info) => {
          if (data.column === info.column && data.row + 1 === info.row) {
            flagForTime = true
          }
        })
        if (flagForTime === false) {
          this.errorForBackdatedExcel.push(`${data.value} time missing for this date`)
        }
      } else {
        arr.forEach((info) => {
          if (data.column === info.column && data.row - 1 === info.row) {
            flagForTime = true
          }
        })
        if (flagForTime === false) {
          this.errorForBackdatedExcel.push(`${data.value} date missing for this time`)
        }
      }
    },
    validateAllValues(arr) {
      // roll number for allocation
      const arrForRollNumber = []
      this.allocatedStudentObject = {}
      this.allExcelObject = {}
      if (this.selectedDataForBackdated.studentAllocatedDataArr && this.selectedDataForBackdated.studentAllocatedDataArr.length > 0) {
        this.selectedDataForBackdated.studentAllocatedDataArr.forEach((stu) => {
          if (stu.rollNumber && stu.rollNumber !== '' && stu.rollNumber !== '-') {
            this.allocatedStudentObject[stu.rollNumber] = stu
            arrForRollNumber.push(stu.rollNumber)
          }
        })
      }
      arr.forEach((data) => {
        // object to avoid multiple iteration over excel data
        this.allExcelObject[`row${data.row}_col${data.column}`] = data.value
        //get max count lec
        if (this.maxColumnCount < data.column) {
          this.maxColumnCount = data.column
        }
        //get max count stu
        if (this.maxRowCount < data.row) {
          this.maxRowCount = data.row
        }
        // validate roll number
        if (data.column === 1 && data.row !== 1 && data.row !== 2) {
          if (!arrForRollNumber.includes(String(data.value))) {
            this.errorForBackdatedExcel.push(`${data.value} rollNumber not valid for on row number ${data.row}`)
          }
        }
        // validate date and time
        if (data.column > 1 && data.row === 1) {
          const result = this.validateDateFormat(data.value)
          if (result.isValid === false) {
            this.errorForBackdatedExcel.push(`${data.value} is not valid date format, ${result.message}`)
          }
          this.checkBothDateAndTimePresent(data, arr, 'checkTime')
        }
        if (data.column > 1 && data.row === 2) {
          const result = this.validateTimeFormat(data.value)
          if (result.isValid === false) {
            this.errorForBackdatedExcel.push(`${data.value} is not valid time format, ${result.message}`)
          }
          this.checkBothDateAndTimePresent(data, arr, 'checkDate')
        }
        // validate p and ab value
        if (data.row > 2 && data.column > 1) {
          const pattern = /^(ab|AB|Ab|p|P)$/; // Using the case-insensitive flag;
          if (!pattern.test(data.value)) {
            this.errorForBackdatedExcel.push(`${data.value} is not correct value. Please enter P or Ab values only`)
          }
        }
      })
    },
    convertToUTCWithTimezone(date, time) {
      const [day, month, year] = date.split('-').map(Number);
      const [hour, minute] = time.split('.').map(Number);
      const localDate = new Date(year, month - 1, day, hour, minute);
      const utcYear = localDate.getUTCFullYear();
      const utcMonth = localDate.getUTCMonth() + 1; // Months are zero-indexed
      const utcDay = localDate.getUTCDate();
      const utcHour = localDate.getUTCHours();
      const utcMinute = localDate.getUTCMinutes();
      const utcSecond = localDate.getUTCSeconds();
      const utcMillisecond = localDate.getUTCMilliseconds();
      // Format the UTC datetime as ISO 8601 format
      const utcDateTime = `${utcYear}-${String(utcMonth).padStart(2, '0')}-${String(utcDay).padStart(2, '0')}T${String(utcHour).padStart(2, '0')}:${String(utcMinute).padStart(2, '0')}:${String(utcSecond).padStart(2, '0')}.${String(utcMillisecond).padStart(3, '0')}Z`;
      return utcDateTime;
    },
    async createLec(arr) {
      try {
        const data = await this.lectureRepositoryInstance.createMultipleLectures(arr)
        this.addedLecInfo = data.lecturesCount
      } catch (error) {
        console.error('error', error);
      }
    },
    async updateLec(arr) {
      try {
        const updateLecArrData = await this.lectureRepositoryInstance.updateMultipleLectureDocument({ bulkDataArray: arr })
        this.updatedLecCount = updateLecArrData.lectureUpdatedCount.nModified
      } catch (error) {
        console.error('error', error);
      }
    },
    async createLecData() {
      this.loaderForTable = true
      const arrForCreateLec = []
      const arrForUpdateLec = []
      for (let i = 2; i <= this.maxColumnCount; i++) {
        const objForLec = {
          date: '',
          startTime: '',
          endTime: '',
          presentRollNumber: [],
          present: [],
          absent: [],
          columnNumber: i,
          uId: this.userData.uId
        }
        objForLec.date = this.allExcelObject[`row${1}_col${i}`]
        objForLec.startTime = this.allExcelObject[`row${2}_col${i}`].split('-')[0]
        objForLec.endTime = this.allExcelObject[`row${2}_col${i}`].split('-')[1]
        // this.allExcelObject
        for (let j = 3; j <= this.maxRowCount; j++) {
          if (this.allExcelObject[`row${j}_col${i}`] === 'P' || this.allExcelObject[`row${j}_col${i}`] === 'p') {
            const rollNumber = String(this.allExcelObject[`row${j}_col${1}`])
            objForLec.presentRollNumber.push(rollNumber)
          }
        }
        objForLec.instituteId = this.selectedInstitute
        objForLec.semId = this.selectedSemester.semId
        objForLec.department = this.selectedDataForBackdated.subjectDetails.department
        objForLec.courseYear = this.selectedDataForBackdated.subjectDetails.courseYear
        objForLec.dateTime = this.convertToUTCWithTimezone(objForLec.date, objForLec.startTime)
        objForLec.startTime = this.convertToUTCWithTimezone(objForLec.date, objForLec.startTime)
        objForLec.endTime = this.convertToUTCWithTimezone(objForLec.date, objForLec.endTime)
        objForLec.isConducted = true
        objForLec.status = 0
        if (this.selectedDataForBackdated.allocationType === 'Division') {
          objForLec.division = this.selectedDataForBackdated.division
        }
        if (this.selectedDataForBackdated.allocationType === 'Batch') {
          objForLec.division = this.selectedDataForBackdated.division
          objForLec.division = this.selectedDataForBackdated.batchName
        }
        if (this.selectedDataForBackdated.allocationType === 'Group') {
          objForLec.groupForSubject = {
            groupName: this.selectedDataForBackdated.groupName,
            groupId: this.selectedDataForBackdated.groupId
          }
        }
        if (this.selectedDataForBackdated.studentAllocatedDataArr && this.selectedDataForBackdated.studentAllocatedDataArr.length > 0) {
          this.selectedDataForBackdated.studentAllocatedDataArr.forEach((stu) => {
            if (stu.reportDate === undefined || stu.reportDate === '' || stu.reportDate === null) {
              if (objForLec.presentRollNumber.includes(stu.rollNumber)) {
                objForLec.present.push(stu.uId)
              } else {
                objForLec.absent.push(stu.uId)
              }
            } else if (objForLec.startTime > stu.reportDate) {
              if (objForLec.presentRollNumber.includes(stu.rollNumber)) {
                objForLec.present.push(stu.uId)
              } else {
                objForLec.absent.push(stu.uId)
              }
            }
          })
        }
        let flagForAlreadyCreated = false
        const arrForLec = [...this.selectedDataForBackdated.conductedLecArr, ...this.selectedDataForBackdated.notConductedLecArr, ...this.selectedDataForBackdated.cancelLecArr]
        if (arrForLec.length > 0) {
          arrForLec.forEach((lec) => {
            if (lec.startTime === objForLec.startTime && lec.endTime === objForLec.endTime) {
              flagForAlreadyCreated = true
              objForLec.lectureId = lec.lectureId
            }
          })
        }
        if (flagForAlreadyCreated === false) {
          arrForCreateLec.push(objForLec)
        } else {
          arrForUpdateLec.push(objForLec)
        }
      }
      if (arrForCreateLec.length > 0) {
        await this.createLec(arrForCreateLec)
      }
      if (arrForUpdateLec.length > 0) {
        await this.updateLec(arrForUpdateLec)
      }
      if (arrForCreateLec.length > 0 || arrForUpdateLec.length > 0) {
        await this.fetchData()
        this.tabChanged()
      }
      this.loaderForTable = false
    },
    async uploadExcelForBackdated() {
      this.errorDialogForBackdatedLec = false
      this.dialogForBackdated = false
      this.errorForBackdatedExcel = []
      this.maxColumn = 0
      const data = await generateJsonFromExcelWithoutSchema(this.backdatedFile, 'flat')
      if (data.Sheet1 && data.Sheet1.length > 0) {
        this.validateAllValues(data.Sheet1)
        if (this.errorForBackdatedExcel.length === 0) {
          this.createLecData(data.Sheet1)
        } else {
          this.errorDialogForBackdatedLec = true
        }
      }
    },
    downloadSampleExcel() {
      createExcelThroughJson([{
        RollNumber: '',
        '13-11-2024': '11.30-13.30'
      }], 'Sample For backdated lecture')
    },
    openDialogForBackdated(data) {
      this.dialogForBackdated = true
      this.backdatedFile = null
      this.selectedDataForBackdated = data
    },
    giveExcelObject(value = '', column, row, bold = false, alignment = 'center', backgroundColor = 'FFFFFF') {
      if (column && row && row > 0 && column > 0) {
        return {
          value: value,
          column: column,
          row: row,
          bold: bold,
          alignment: alignment,
          backgroundColor: backgroundColor,
          border: true
        }
      }
    },
    getCurrentDateTime() {
      const now = new Date();

      // Format date
      const date = now.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      });

      // Format time with AM/PM
      const time = now.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      });
      this.today = `${date} ${time}`
    },
    downloadExcelReport() {
      const titleColor = 'ffffcc00'
      const headingColor = 'ff91d2ff'
      this.getCurrentDateTime()
      const data = []
      const titleArr = ['Semester Name', 'Department', 'Course Year', 'Division', 'Subject Name', 'Batch', 'Group Name', 'Faculty Name', 'Date Range', 'Report time']
      const length = titleArr.length + 2
      this.tabNames.forEach((tab, i) => {
        const finalObj = {
          name: i === 0 ? 'Overall' : `sub_${i}`,
          data: []
        }
        if (i === 0) {
          for (let j = 1; j < titleArr.length + 1; j++) {
            finalObj.data.push(this.giveExcelObject(titleArr[j - 1], 1, j, true, 'center', titleColor))

            if (titleArr[j - 1] === 'Semester Name') {
              finalObj.data.push(this.giveExcelObject(this.selectedSemester.semName, 2, j, false, 'center', ''))
            }
            if (titleArr[j - 1] === 'Report time') {
              finalObj.data.push(this.giveExcelObject(this.today, 2, j, false, 'center', ''))
            }
            if (titleArr[j - 1] === 'Date Range') {
              finalObj.data.push(this.giveExcelObject(this.startDate + ' To ' + this.endDate, 2, j, false, 'center', ''))
            }
            if (titleArr[j - 1] === 'Faculty Name') {
              finalObj.data.push(this.giveExcelObject(this.userName, 2, j, false, 'center', ''))
            }
          }
          if (this.overallHeaders.length > 0 && this.overallData.length > 0) {
            this.overallHeaders.forEach((hed, j) => {
              finalObj.data.push(this.giveExcelObject(hed.text, j + 1, length, true, 'center', headingColor))
              this.overallData.forEach((overallValue, k) => {
                finalObj.data.push(this.giveExcelObject(overallValue[hed.value], j + 1, length + k + 1, false, 'center', ''))
              })
            })
          }
        } else {
          const head = this.allocationDetails[i].header
          const stuData = this.allocationDetails[i].itemData
          const selectedData = this.allocationDetails[i]
          for (let j = 1; j < titleArr.length + 1; j++) {
            finalObj.data.push(this.giveExcelObject(titleArr[j - 1], 1, j, true, 'center', titleColor))
            if (titleArr[j - 1] === 'Semester Name') {
              finalObj.data.push(this.giveExcelObject(this.selectedSemester.semName, 2, j, false, 'center', ''))
            }
            if (titleArr[j - 1] === 'Department') {
              finalObj.data.push(this.giveExcelObject(selectedData.subjectDetails.department, 2, j, false, 'center', ''))
            }
            if (titleArr[j - 1] === 'Course Year') {
              finalObj.data.push(this.giveExcelObject(selectedData.subjectDetails.courseYear, 2, j, false, 'center', ''))
            }
            if (titleArr[j - 1] === 'Subject Name') {
              finalObj.data.push(this.giveExcelObject(selectedData.subjectDetails.subjectName, 2, j, false, 'center', ''))
            }
            if (titleArr[j - 1] === 'Report time') {
              finalObj.data.push(this.giveExcelObject(this.today, 2, j, false, 'center', ''))
            }
            if (titleArr[j - 1] === 'Date Range') {
              finalObj.data.push(this.giveExcelObject(this.startDate + ' To ' + this.endDate, 2, j, false, 'center', ''))
            }
            if (titleArr[j - 1] === 'Faculty Name') {
              finalObj.data.push(this.giveExcelObject(this.userName, 2, j, false, 'center', ''))
            }
            if (selectedData.allocationType === 'Division' || selectedData.allocationType === 'Batch') {
              if (titleArr[j - 1] === 'Division') {
                finalObj.data.push(this.giveExcelObject(selectedData.division, 2, j, false, 'center', ''))
              }
            }
            if (selectedData.allocationType === 'Batch') {
              if (titleArr[j - 1] === 'Batch') {
                finalObj.data.push(this.giveExcelObject(selectedData.batchName, 2, j, false, 'center', ''))
              }
            }
            if (selectedData.allocationType === 'Group') {
              if (titleArr[j - 1] === 'Group Name') {
                finalObj.data.push(this.giveExcelObject(selectedData.groupName, 2, j, false, 'center', ''))
              }
            }
          }
          if (head.length > 0 && stuData.length > 0) {
            head.forEach((hed, k) => {
              let value1 = hed.text
              if (hed.text !== 'Name' && hed.value !== 'rollNumber' && hed.value !== 'collagePRNNo' && hed.value !== 'overallAttendance' && hed.value !== 'mentor') {
                value1 = value1 + ' ( ' + hed.startTime + ' - ' + hed.endTime
              }
              finalObj.data.push(this.giveExcelObject(value1, k + 1, length, true, 'center', headingColor))
              stuData.forEach((stu, h) => {
                finalObj.data.push(this.giveExcelObject(stu[hed.value], k + 1, length + h + 1, false, 'center', ''))
              })
            })
          }
        }
        data.push(finalObj)
      })
      createMultiSheetStyledExcel(data, `Allocation Attendance Report ${this.userName}`)
    },
    tabChanged() {
      if (this.tab !== 0) {
        this.headerForAllocation = []
        this.studentData = []
        this.headerForAllocation = [...this.allocationDetails[this.tab]['header']]
        this.studentData = [...this.allocationDetails[this.tab]['itemData']]
        this.applyDefaultLimit()
      }
    },
    async fetchData() {
      this.flagForShow = true
      this.getDepartmentName()
      await this.getSemDetails()
      await this.getStudentForAnalysis()
      await this.getLecForAnalysis()
      this.createTableData()
    },
    createTableData() {
      this.overallData = []
      if (this.allocationDetails.length > 0) {
        this.allocationDetails.forEach((item) => {
          if (item.type !== 'Overall') {
            const objToPush = {
              department: item.subjectDetails.department,
              courseYear: item.subjectDetails.courseYear,
              subjectName: item.subjectDetails.subjectName,
              conductedCount: 0,
              notConductedCount: 0,
              cancelCount: 0,
              allocatedStudentCount: 0,
              overallAttendance: 0
            }
            const allocationTypeMap = {
              Batch: `Division: ${item.division} | Batch: ${item.batchName}`,
              Division: `Division: ${item.division}`,
              Group: `Group: ${item.groupName}`
            };

            objToPush.allocation = allocationTypeMap[item.allocationType] || '';
            objToPush.allocatedStudentCount = this.findAllocationStudentCount(item)
            item.allocatedStudentCount = objToPush.allocatedStudentCount
            const { conductedCount, notConductedCount, cancelCount, overallAttendance } = this.findLecCount(item);
            Object.assign(objToPush, { conductedCount, notConductedCount, cancelCount, overallAttendance });
            Object.assign(item, { conductedCount, notConductedCount, cancelCount, overallAttendance });
            this.prepareTableDataForEachAllocation(item)
            objToPush.overallAttendance = item.overallAttendance
            this.overallData.push(objToPush)
          }
        })
      }
    },
    prepareTableDataForEachAllocation(item, arrName = 'conductedLecArr') {
      // this.headerForAllocation
      item.overallAttendance = 0
      item.header = []
      this.headerForAllocationDummy.forEach((hed) => {
        item.header.push(hed)
      })
      item.itemData = []
      item.header.push({
        text: `${item.subjectDetails.subjectName} Overall attendance (%)`,
        value: 'overallAttendance',
        width: '150px'
      })
      if (Array.isArray(item[arrName]) && item[arrName].length > 0) {
        item[arrName].forEach((lec) => {
          item.header.push({
            text: lec.startTime.split('T')[0],
            startTime: convertUTCDateToLocalDate(lec.startTime),
            endTime: convertUTCDateToLocalDate(lec.endTime),
            value: lec.lectureId,
            width: '200px'
          })
        })
        if (Array.isArray(item.studentAllocatedDataArr) && item.studentAllocatedDataArr.length > 0) {
          item.studentAllocatedDataArr.forEach((stu) => {
            const obj = {
              fullName: stu.firstName + ' ' + stu.middleName + ' ' + stu.lastName,
              mentor: stu.assignedMentor ? this.allUserObject[stu.assignedMentor] : '',
              rollNumber: stu.rollNumber ? stu.rollNumber : '-',
              collagePRNNo: stu.collagePRNNo ? stu.collagePRNNo : '-',
              overallAttendance: 0,
              presentCount: 0
            }
            let conductedLecCount = 0
            item[arrName].forEach((lec) => {
              const { uId } = stu;
              const { lectureId, present, absent } = lec;
              if (lec.isConducted === true) {
                if (lec.present.includes(uId) || lec.absent.includes(uId)) {
                  conductedLecCount++;
                }
                if (present.includes(uId)) {
                  obj.presentCount++
                  obj[lectureId] = 'Present';
                } else if (absent.includes(uId)) {
                  obj[lectureId] = 'Absent';
                } else {
                  obj[lectureId] = 'NA';
                }
              } else {
                if (lec.status === 6) {
                  obj[lectureId] = 'Canceled'
                } else {
                  obj[lectureId] = 'Not Conducted'
                }
              }
            })
            if (obj.presentCount > 0) {
              obj.overallAttendance = (obj.presentCount * 100 / conductedLecCount).toFixed(2)
              item.overallAttendance = item.overallAttendance + Number(obj.overallAttendance)
            }
            item.itemData.push(obj)
          })
          item.overallAttendance = (item.overallAttendance / item.studentAllocatedDataArr.length).toFixed(2)
        }
      }
    },
    findLecCount(item) {
      const obj = {
        conductedCount: 0,
        attended: 0,
        notConductedCount: 0,
        cancelCount: 0,
        overallAttendance: 0,
        totalStudentPresentCount: 0
      };
      item.conductedLecArr = []
      item.cancelLecArr = []
      item.notConductedLecArr = []
      item.overallAttendance = 0
      const { department, courseYear, subjectId } = item.subjectDetails;
      if (this.allLec.length > 0) {
        this.allLec.forEach((lec) => {
          if (lec.department === department && lec.courseYear === courseYear && lec.subjectId === subjectId) {
            switch (item.allocationType) {
              case 'Batch':
                if (lec.division === item.division && lec.batch === item.batchName) {
                  if (lec.status === 6) {
                    item.cancelLecArr.push(lec)
                    obj.cancelCount++;
                  } else {
                    if (lec.isConducted === true) {
                      item.conductedLecArr.push(lec)
                      obj.conductedCount++;
                      obj.totalStudentPresentCount = obj.totalStudentPresentCount + lec.present.length;
                    } else {
                      item.notConductedLecArr.push(lec)
                      obj.notConductedCount++;
                    }
                  }
                }
                break;

              case 'Division':
                if (lec.division === item.division) {
                  if (lec.status === 6) {
                    item.cancelLecArr.push(lec)
                    obj.cancelCount++;
                  } else {
                    if (lec.isConducted === true) {
                      item.conductedLecArr.push(lec)
                      obj.conductedCount++;
                      obj.totalStudentPresentCount = obj.totalStudentPresentCount + lec.present.length;
                    } else {
                      item.notConductedLecArr.push(lec)
                      obj.notConductedCount++;
                    }
                  }
                } else if (Array.isArray(lec.multiDivision) && lec.multiDivision.includes(item.division)) {
                  if (lec.status === 6) {
                    item.cancelLecArr.push(lec)
                    obj.cancelCount++;
                  } else {
                    if (lec.isConducted === true) {
                      item.conductedLecArr.push(lec)
                      obj.conductedCount++;
                      const count = this.calculateStudentCountForDivFromLec(lec.present, lec.department, lec.courseYear, item.division)
                      obj.totalStudentPresentCount = obj.totalStudentPresentCount + count;
                    } else {
                      item.notConductedLecArr.push(lec)
                      obj.notConductedCount++;
                    }
                  }
                }
                break;

              case 'Group':
                if (Array.isArray(lec.groupForSubject) && lec.groupForSubject.some(grp => grp.groupId === item.groupId)) {
                  if (lec.status === 6) {
                    item.cancelLecArr.push(lec)
                    obj.cancelCount++;
                  } else {
                    if (lec.isConducted === true) {
                      item.conductedLecArr.push(lec)
                      obj.conductedCount++;
                      obj.totalStudentPresentCount = obj.totalStudentPresentCount + lec.present.length;
                    } else {
                      item.notConductedLecArr.push(lec)
                      obj.notConductedCount++;
                    }
                  }
                }
                break;

              default:
                break;
            }
          }
        })
      }
      if (obj.conductedCount > 0 && obj.totalStudentPresentCount > 0 && item.allocatedStudentCount > 0) {
        obj.overallAttendance = ((obj.totalStudentPresentCount * 100) / (obj.conductedCount * item.allocatedStudentCount)).toFixed(2)
        item.overallAttendance = obj.overallAttendance
      }
      return obj
    },
    calculateStudentCountForDivFromLec(arr, dept, cy, div) {
      let count = 0
      if (this.allUserData.length > 0) {
        this.allUserData.forEach((stu) => {
          if (arr.includes(stu.uId) && stu.department === dept && stu.courseYear === cy && stu.division === div && stu.batch === '') {
            count++;
          }
        })
      }
      return count
    },
    findAllocationStudentCount(item) {
      let allocatedStudentCount = 0;
      const { department, courseYear, subjectId } = item.subjectDetails;
      item.studentAllocatedDataArr = []
      this.allUserData.forEach((stu) => {
        if (stu.department !== department || stu.courseYear !== courseYear || !stu.subjects.includes(subjectId)) return;
        switch (item.allocationType) {
          case 'Batch':
            if (stu.division === item.division && stu.batch === item.batchName) {
              item.studentAllocatedDataArr.push(stu)
              allocatedStudentCount++;
            }
            break;

          case 'Division':
            if (stu.division === item.division) {
              item.studentAllocatedDataArr.push(stu)
              allocatedStudentCount++;
            }
            break;

          case 'Group':
            if (Array.isArray(stu.assignedGroupForSubject) && stu.assignedGroupForSubject.some(grp => grp.groupId === item.groupId)) {
              item.studentAllocatedDataArr.push(stu)
              allocatedStudentCount++;
            }
            break;

          default:
            break;
        }
      });

      return allocatedStudentCount;
    },

    async getSemDetails() {
      this.endDate = new Date().toISOString().split('T')[0]
      try {
        const data = await this.SemesterRepositoryInstance.getSemestersBySemId(
          {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId
          }
        )
        if (this.startDate === '') {
          this.startDate = data.length > 0 ? data[0].startDate.split('T')[0] : ''
        }
      } catch (error) {
        console.error('error', error);
        if (this.startDate === '') {
          this.startDate = '2023-09-01'
        }
      }
    },
    async getStudentForAnalysis() {
      try {
        this.allUserData = await this.semesterUserRepositoryInstance.getSemUserWithNameForAttendanceAnalysis(
          {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId
          }
        )
        this.allUserObject = {}
        if (this.allUserData.length > 0) {
          this.allUserData.forEach((user) => {
            this.allUserObject[user.uId] = user.firstName + ' ' + user.middleName + ' ' + user.lastName
          })
        }
      } catch (error) {
        console.error('error', error);
      }
    },
    async getLecForAnalysis() {
      const currentDate = new Date(this.startDate);
      currentDate.setDate(currentDate.getDate() - 1);
      const startDate = currentDate.toISOString()
      const startDateForLec = startDate.split('T')[0]
      const min = `${startDateForLec}T18:30:00`
      const max = `${this.endDate}T18:30:59`
      this.allLec = []
      try {
        this.allLec = await this.lectureRepositoryInstance.getLectureForAnyCriteriaForSpecificDateRange({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: this.userData.uId,
          fromDate: min,
          toDate: max
        })
      } catch (error) {
        console.error('error', error);
      }
    },

    async getDepartmentName() {
      this.listForDept = []
      this.subjectIdsAllocated = []
      this.tabNames = []
      this.allocationDetails = []
      if (Array.isArray(this.userData.subjects) && this.userData.subjects.length > 0) {
        this.userData.subjects.map((item) => {
          this.listForDept.push(item.department)
          this.subjectIdsAllocated.push(item.subjectId)
        })
      }
      console.log('this.userData', this.userData);
      if (Array.isArray(this.userData.subjectAllocatedForDiv) && this.userData.subjectAllocatedForDiv.length > 0) {
        this.userData.subjectAllocatedForDiv.map((item) => {
          let flag = false
          // console.log('tab1');
          if (this.userData.assignedGroupForSubject.length > 0) {
            flag = this.userData.assignedGroupForSubject.some((allocation) => allocation.subjectId === item.subjectDetails.subjectId)
          }
          // console.log('tab2');
          // console.log('flag', flag, item.subjectDetails.subjectName);

          if (flag === false) {
            if (item.batchName !== '') {
              this.tabNames.push(`${item.subjectDetails.subjectName}_${item.batchName}`)
              item.allocationType = 'Batch'
              this.allocationDetails.push(item)
            } else {
              this.tabNames.push(`${item.subjectDetails.subjectName}_${item.division}`)
              item.allocationType = 'Division'
              this.allocationDetails.push(item)
            }
          }
        })
      }
      if (Array.isArray(this.userData.assignedGroupForSubject) && this.userData.assignedGroupForSubject.length > 0) {
        this.userData.assignedGroupForSubject.map((item) => {
          console.log('tab3');

          this.tabNames.push(`${item.subjectDetails.subjectName}_${item.groupName}`)
          item.allocationType = 'Group'
          this.allocationDetails.push(item)
        })
      }
      this.tabNames.unshift('Overall')
      this.allocationDetails.unshift({
        type: 'Overall'
      })
      this.listForDept = [...new Set(this.listForDept)]
    },
    validateTimeFormat(timeRange) {
      // Regex pattern to match HH.mm-HH.mm format (24-hour time)
      const timeRangePattern = /^([01]\d|2[0-3])\.[0-5]\d-([01]\d|2[0-3])\.[0-5]\d$/;

      // Check if input matches the pattern
      if (!timeRangePattern.test(timeRange)) {
        return { isValid: false, message: "Invalid format. Use HH.mm-HH.mm in 24-hour time." };
      }

      // Parse start and end times
      const [start, end] = timeRange.split('-');
      const [startHour, startMinute] = start.split('.').map(Number);
      const [endHour, endMinute] = end.split('.').map(Number);

      // Validate start time is before end time
      if (startHour > endHour || (startHour === endHour && startMinute >= endMinute)) {
        return { isValid: false, message: "Start time must be earlier than end time." };
      }

      return { isValid: true, message: "Valid time range." };
    },
    validateDateFormat(inputDate) {
      // Regex pattern to match DD-MM-YYYY format
      const dateFormatPattern = /^(0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-\d{4}$/;

      // Check if input matches the pattern
      if (!dateFormatPattern.test(inputDate)) {
        return { isValid: false, message: "Invalid format. Use DD-MM-YYYY." };
      }

      // Parse the date components
      const [day, month, year] = inputDate.split('-').map(Number);

      // Validate the month and day based on month-specific day ranges
      const daysInMonth = new Date(year, month, 0).getDate(); // Get days in the given month/year
      if (day > daysInMonth) {
        return { isValid: false, message: `Invalid day for the given month.` };
      }

      // Validate the date is not in the future
      const inputDateObject = new Date(year, month - 1, day); // Month is 0-indexed in JS Date
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);

      if (inputDateObject >= tomorrow) {
        return { isValid: false, message: "Date should be less than tomorrow." };
      }

      return { isValid: true, message: "Valid date." };
    }
  }
}
</script>
<style>
.defaulterBackgroundColor {
  background-color: #fceeee !important;
}

.nonDefaulter {}

.styled-table>.v-data-table__wrapper>table>tbody>tr>td:nth-child(1),
.styled-table>.v-data-table__wrapper>table>tbody>tr>td:nth-child(2) {
  position: sticky;
  background-color: #f2f3fa;
  color: black;
  z-index: 1;
}

.styled-table>.v-data-table__wrapper>table>tbody>tr>td:nth-child(1) {
  left: 0;
}

.styled-table>.v-data-table__wrapper>table>tbody>tr>td:nth-child(2) {
  left: 100px;
}
</style>
<style src='./activityCumulativeAttendance.css' scoped></style>
