<template src="./activityTopics.html"></template>

<script>
import inputContainer from '../../Components/inputContainer'
import smallButton from '../../Components/smallButton'
import LectureRepository from '../../Repository/lectures'
import TopicRepository from '../../Repository/Topic'
import TopicFeedback from '../../Repository/TopicFeedback'
import NbaRepository from "../../Repository/NBA";
import SyllabusRepository from "../../Repository/Syllabus";
import axios from 'axios'
import { createExcelThroughJson, generateJsonFromExcel } from "../../utils/excel";

import {
  formatToSlashSepearated,
  timeStringConverter,
  convertDate, formatToSlashSepearatedDate, convertUTCDateToLocalDate
} from '../../Services/Utils/DateTimeFormatter'

export default {
  name: 'activityTopics',
  components: {
    inputContainer,
    smallButton
  },
  props: ['prop_subject', 'prop_type', 'prop_groupInfo'],
  data() {
    return {
      arrForTopicCoverage: [],
      syncDialog: false,
      lectureOfFaculty: [],
      syllabus: [],
      isNicmar: false,
      isNaralkar: false,
      dialog: false,
      showTooltip: false,
      modalVisible: false,
      topicNameByExcel: [],
      lecturesList: [],
      createLecturesList: [],
      lectureIds: [],
      topics: [],
      isMobileView: false,
      uploadTopicDialog: false,
      loading: false,
      dates: [],
      file: null,
      datesBackup: [],
      userData: {},
      addingTopic: false,
      editbtn: false,
      updatingTopic: false,
      subjectProgress: '',
      topicsList: [],
      editClicked: false,
      headers: [
        {
          text: 'Name Of Topic',
          value: 'topicName'
        },
        {
          text: 'Sub Topic',
          value: 'subTopics'
        },
        // {
        //   text: 'Start Date',
        //   value: 'startDate'
        // },
        // {
        //   text: 'End Date',
        //   value: 'endDate'
        // },
        {
          text: 'Edit',
          value: 'edit'
        }
        // {
        //   text: 'Feedback',
        //   value: 'status',
        //   align: 'center'
        // }
      ],
      tempTopicName: '',
      selectedTopic: {},
      topicsMapping: {},
      headers2: [
        {
          text: '',
          value: 'selection'
        },
        {
          text: 'Date',
          value: 'date'
        },
        {
          text: 'time',
          value: 'lectureTiming'
        }
      ],
      headers3: [
        {
          text: '',
          value: 'isConducted'
        },
        {
          text: 'Date',
          value: 'date'
        },
        {
          text: 'time',
          value: 'lectureTiming'
        },
        {
          text: '',
          value: 'action'
        }
      ],
      headers4: [
        {
          text: '',
          value: 'statusDot'
        },
        {
          text: 'Question',
          value: 'question'
        },
        {
          text: 'Average Answer',
          value: 'averageAnswer'
        }
      ],
      selection: [],
      isTopicSelected: false,
      selectedSubject: {},
      topicsObject: {},
      processingDate: false,
      showStudentFeedback: false,
      topicFeedbackQuestions: [],
      isFeedbackLaunched: false,
      feedbacks: [],
      count: 0,
      isImplementedCountNumber: 0,
      isAllTopicCount: 0,
      type: '',
      groupInfo: {},
      courseOutcomeData: [],
      selectedCos: [],
      selectedBlooms: [],
      allBlooms: [
        {
          name: "CREATE (C)",
          shortCode: "C",
          description:
            "Produce new or original work (Verbs : Design, assemble, contract, conjecture, develop, formulate, author, investigate)"
        },
        {
          name: "EVALUATE (E)",
          shortCode: "E",
          description:
            "Justify a stand or decision (Verbs : appraise, argue, defend, judge, select, support, value, critique, weigh)"
        },
        {
          name: "ANALYZE (A)",
          shortCode: "A",
          description:
            "Draw connections among ideas (Verbs : differentiate, organise, relate, compare, contrast, distinguish, examine, experiment, question, test)"
        },
        {
          name: "APPLY (Ap)",
          shortCode: "Ap",
          description:
            "Use information in new situations (Verbs : execute, implement, solve, use, demonstrate, interpret, operate, schedule, sketch)"
        },
        {
          name: "UNDERSTAND (U)",
          shortCode: "U",
          description:
            "Explain ideas of concepts (Verbs : classify, describe, discuss, explain, identify, locate, recognise, report, select, translate)"
        },
        {
          name: "REMEMBER (R)",
          shortCode: "R",
          description:
            "Recall facts and basic concepts (Verbs : define, duplicate, list, memorise, repeat, state)"
        }
      ]
    }
  },
  async created() {
    console.log('isNaralkar', this.isNaralkar);
    this.$store.commit('liveData/set_selectedActivityName', ' Planning')
    this.type = this.prop_type
    this.isMobileView = this.$store.getters['liveData/get_isMobileView']
    if (this.prop_subject) {
      this.selectedSubject = JSON.parse(JSON.stringify(this.prop_subject))
    } else {
      this.selectedSubject = JSON.parse(
        JSON.stringify(this.$store.getters['liveData/get_selectedSubject'])
      )
    }
    this.lectureRepositoryInstance = new LectureRepository(this)
    this.topicRepositoryInstance = new TopicRepository(this)
    this.topicFeedbackRepository = new TopicFeedback(this)
    this.nbaRepositoryInstance = new NbaRepository(this);
    this.syllabusRepositoryInstance = new SyllabusRepository(this);
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters[
      'instituteData/get_selectedInstitute'
    ]
    this.selectedSemester = this.$store.getters[
      'instituteData/get_selectedSemester'
    ]
    if (this.selectedInstitute === "86db902d-4346-4ef5-ad36-e1416d3306ae") {
      this.isNicmar = true
    }
    if (this.selectedInstitute === "a2459a84-980b-4f07-82da-7f1f45616c39") {
      this.isNaralkar = true
    }
    await this.fetchData()
    await this.getCourseOutcome()
  },
  methods: {
    convertUTCtoLocalTimeWithAMPM(utcDateString) {
      const utcDate = new Date(utcDateString);
      const hours = utcDate.getHours();
      const minutes = utcDate.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 || 12;
      const result = formattedHours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + ampm;
      return result;
    },
    async getSyllabus() {
      try {
        const data = await this.syllabusRepositoryInstance.getSyllabus({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedSubject.department,
          courseYear: this.selectedSubject.courseYear,
          subjectId: this.selectedSubject.subjectId
        })
        this.syllabus = data?.result
        console.log('data', data);
      } catch (e) {
        console.error(e)
      }
    },
    async syncTopic(finalArr) {
      try {
        await this.topicRepositoryInstance.createTopicMultipleTopics(finalArr)
        await this.fetchData()
      } catch (e) {
        console.error(e)
      }
    },
    findTopicToAdd() {
      const arrForIds = []
      const finalTopicArr = []

      console.log('this.syllabus', this.syllabus);
      const allSyllabusTopic = []
      if (this.syllabus?.units) {
        if (Array.isArray(this.syllabus.units) && this.syllabus.units.length > 0) {
          this.syllabus.units.map((unit) => {
            if (Array.isArray(unit.topics) && unit.topics.length > 0) {
              console.log('topic');
              unit.topics.map((topic) => {
                allSyllabusTopic.push(topic)
              })
            }
          })
        }
      }
      // console.log('allSyllabusTopic', allSyllabusTopic);
      if (this.topics.length > 0) {
        this.topics.map((item) => {
          if (item.courseFileTopicId && item.courseFileTopicId !== '') {
            arrForIds.push(item.courseFileTopicId)
          }
        })
      }
      // console.log('arrForIds', arrForIds);
      if (allSyllabusTopic.length > 0) {
        allSyllabusTopic.map((topic) => {
          if (!arrForIds.includes(topic.topicId)) {
            const obj = {
              topicName: topic.topicName,
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              department: this.selectedSubject.department,
              courseYear: this.selectedSubject.courseYear,
              subjectId: this.selectedSubject.subjectId,
              courseFileTopicId: topic.topicId,
              subTopics: topic.subTopics
            }
            if (this.prop_type === "Group") {
              obj.assignedGroupForSubject = {
                groupName: this.prop_groupInfo.groupName,
                groupId: this.prop_groupInfo.groupId
              }
              obj.division = ""
            }
            if (this.prop_type === "Division") {
              obj.division = this.selectedSubject.division
            }
            if (this.prop_type === "Batch") {
              obj.division = this.selectedSubject.division
              obj.batch = this.selectedSubject.batch
            }
            finalTopicArr.push(obj)
          }
        })
      }
      return finalTopicArr
    },
    async syncTopicWithCourseFileSyllabus() {
      this.syncDialog = false
      await this.getSyllabus()
      const finalArr = this.findTopicToAdd()
      // console.log('finalArr', finalArr);
      if (finalArr.length > 0) {
        await this.syncTopic(finalArr)
      }
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    openModal() {
      this.modalVisible = true;
    },
    closeModal() {
      // console.log("check", this.selectedCos)
      this.selectedCos = [];
      this.selectedBlooms = [];
      this.editClicked = false;
      this.modalVisible = false;
    },
    handleGoBack() {
      history.back()
    },
    async getCourseOutcome() {
      try {
        this.courseOutcomeData = [];
        // this.COFromNba = [];
        // this.assignedCoArray = [];
        const cos = await this.nbaRepositoryInstance.getCos({
          lmsInstituteId: this.selectedInstitute,
          semesterId: this.selectedSemester.semId,
          subjectId: this.selectedSubject.subjectId
        });
        if (Array.isArray(cos.data)) {
          cos.data.forEach((nba) => {
            this.courseOutcomeData.push({
              id: nba._id,
              coNumber: nba.CONumber,
              coName: nba.COName
            });
          });
        } else {
          // console.log("No COs Added")
        }
      } catch (err) {
        console.log(err);
      }
    },
    async handleParseMakrsExcel() {
      this.loading = true
      const schema = {
        Sheet1: {
          "Add Topic names below": {
            validation: {
              type: 'textLength',
              required: true
            },
            variableName: 'name'
          }
        }
      }
      const res = await generateJsonFromExcel(this.file, schema, false);
      // console.log('res', res);
      const { data } = res;
      const { Sheet1 } = data;
      this.topicNameByExcel = []
      Sheet1.map((obj) => {
        // console.log('obj', obj);
        if (obj.name) {
          const objToPush = {
            instituteId: this.selectedSubject.instituteId,
            semId: this.selectedSubject.semId,
            department: this.selectedSubject.department,
            courseYear: this.selectedSubject.courseYear,
            // batch: this.selectedSubject.batch,
            subjectId: this.selectedSubject.subjectId,
            topicName: obj.name
          }
          if (this.prop_type === "Group") {
            objToPush.assignedGroupForSubject = {
              groupName: this.prop_groupInfo.groupName,
              groupId: this.prop_groupInfo.groupId
            }
            objToPush.division = ""
          }
          if (this.prop_type === "Division") {
            objToPush.division = this.selectedSubject.division
          }
          if (this.prop_type === "Batch") {
            objToPush.division = this.selectedSubject.division
            objToPush.batch = this.selectedSubject.batch
          }
          this.topicNameByExcel.push(objToPush)
        }
      })
      if (this.topicNameByExcel.length > 0) {
        await this.topicRepositoryInstance.createTopicMultipleTopics(this.topicNameByExcel)
        await this.fetchData()
      }
      this.uploadTopicDialog = false
      this.loading = false
    },
    handleGenerateExcel() {
      const data = [{
        "Add Topic names below": ''
      }];
      createExcelThroughJson(data, 'Add Topic sample')
    },
    async uploadExcel() {
      this.loading = true
      const formData = new FormData()
      formData.append('excel', this.file)
      formData.append('instituteId', this.userData.instituteId)
      formData.append('semId', this.userData.semId)
      formData.append('department', this.selectedSubject.department)
      formData.append('courseYear', this.selectedSubject.courseYear)
      formData.append('division', this.selectedSubject.division)
      formData.append('batch', this.selectedSubject.batch)
      formData.append('subjectId', this.selectedSubject.subjectId)
      axios.post(process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_API_PORT + '/excel/topic/parse',
        formData).then((response) => {
          this.loading = false
          this.uploadTopicDialog = false
          this.fetchData()
        }).catch((error) => {
          this.loading = false
          console.log(error)
        })
      this.loading = false
    },
    async fetchData() {
      // console.log('hello')
      this.selectedTopic = {}
      this.selection = []
      this.lecturesList = []
      this.dates = []
      this.topicsList = []
      this.topics = []
      this.topicsMapping = {}

      try {
        this.topics = await this.topicRepositoryInstance.getTopicsOfASubjectOfAFaculty(
          this.selectedSubject
        )
        // console.log('this.topics', this.topics);
        if (this.prop_type === "Division") {
          let onlyDivisionMaterial = []
          if (this.topics.length > 0) {
            onlyDivisionMaterial = this.topics.filter((data) => data.division === this.prop_subject.division && (data.batch === undefined || data.batch === ''))
            this.topics = onlyDivisionMaterial
          }
        }
        if (this.prop_type === "Batch") {
          let onlyDivisionMaterial = []
          if (this.topics.length > 0) {
            onlyDivisionMaterial = this.topics.filter((data) => data.division === this.prop_subject.division && data.batch === this.prop_subject.batch)
            this.topics = onlyDivisionMaterial
          }
        }
        if (this.prop_type === "Group") {
          let onlyGroupMaterial = []
          if (this.topics.length > 0) {
            onlyGroupMaterial = this.topics.filter((data) => data.assignedGroupForSubject?.groupId === this.prop_groupInfo.groupId)
            this.topics = onlyGroupMaterial
          }
        }
        if (this.topics.length) {
          this.isAllTopicCount = this.topics.length;
        }
        this.isImplementedCountNumber = 0
        this.topics.forEach(topic => {
          if (topic.isImplemented === true) {
            this.isImplementedCountNumber++
          }
          this.lectureIds.push(topic.lectureId)
        });
        this.topicsObject = this.topics.reduce((group, topic) => {
          const { topicId } = topic;
          group[topicId] = group[topicId] ?? [];
          group[topicId].push(topic);
          return group;
        }, {});
        // for (const [key, value] of Object.entries(this.topicsObject)) {

        // }
        this.fetchLectures()
        this.isTopicSelected = false
        if (this.isAllTopicCount > 0) {
          this.subjectProgress = ((this.isImplementedCountNumber / this.isAllTopicCount) * 100).toFixed(0)
        } else {
          this.subjectProgress = 0
        }
        this.getLecForFacultyForSubject()
      } catch (err) {
        console.log('error');
        console.log(err)
      }
    },
    async fetchLectures() {
      const objToPush = {
        lectures: this.lectureIds
      }
      this.lecturesList = await this.lectureRepositoryInstance.getLecturesBySubject(
        objToPush
      )
      for (const [key, value] of Object.entries(this.topicsObject)) {
        if (key !== 'undefined') {
          value[0].conductedCount = 0
          value[0].implementedCount = 0
          const dates = []
          value.map((val) => {
            this.lecturesList.map((lecture) => {
              if (val.lectureId === lecture.lectureId) {
                if (lecture.isConducted === true) {
                  value[0].conductedCount++
                }
                if (val.isImplemented === true) {
                  value[0].implementedCount++
                }
                if (lecture.dateTime) {
                  if (!(dates.includes(lecture.dateTime))) {
                    dates.push(lecture.dateTime)
                  }
                }
              }
            })
          })
          value[0].startDate = dates[0] ? dates[0].slice(0, 10) : "-"
          value[0].endDate = dates[0] ? dates[dates.length - 1].slice(0, 10) : "-"
          value[0].lectureCount = 0
          value[0].lectureCount = value.length
          this.topicsList.push(value[0])
        }
      }
    },
    async getLecForFacultyForSubject() {
      try {
        const data = await this.lectureRepositoryInstance.getLecturesOfAFacultyOfASubject(
          {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            uId: this.userData.uId,
            subjectId: this.prop_subject.subjectId
          }
        )
        this.lectureOfFaculty = []
        // console.log('data', data);
        if (this.prop_type === "Division") {
          let onlyDivLec = []
          if (data.length > 0) {
            onlyDivLec = data.filter((lec) => lec.division === this.prop_subject.division && (lec.batch === undefined || lec.batch === ''))
            this.lectureOfFaculty = onlyDivLec
          }
        }
        if (this.prop_type === "Batch") {
          let onlyDivLec = []
          if (data.length > 0) {
            onlyDivLec = data.filter((lec) => lec.division === this.prop_subject.division && lec.batch === this.prop_subject.batch)
            this.lectureOfFaculty = onlyDivLec
          }
        }
        if (this.prop_type === "Group") {
          const onlyDivLec = []
          if (data.length > 0) {
            data.forEach((lec) => {
              if (Array.isArray(data.groupForSubject) && data.groupForSubject.length > 0) {
                if (data.groupForSubject[0].groupId === this.prop_groupInfo.groupId) {
                  onlyDivLec.push(lec)
                }
              }
            })
            this.lectureOfFaculty = onlyDivLec
          }
        }
        // console.log('this.lectureOfFaculty', this.lectureOfFaculty);
        // console.log('this.lectureOfFaculty', this.topicsList);
        this.arrForTopicCoverage = []
        if (this.topicsList.length > 0) {
          this.topicsList.forEach((topic) => {
            if (Array.isArray(topic.subTopics) && topic.subTopics.length > 0) {
              topic.subTopics.forEach((sub) => {
                const obj = {
                  topicName: topic.topicName,
                  subTopicName: sub.subTopicName,
                  subTopicStartDate: '',
                  subTopicEndDate: '',
                  lecForPlanning: [],
                  lecForCovering: [],
                  lectureDetailsForPlanning: [],
                  lectureDetailsForCovering: []
                }
                if (this.lectureOfFaculty.length > 0) {
                  this.lectureOfFaculty.sort((a, b) => new Date(a.date) - new Date(b.date));
                  this.lectureOfFaculty.forEach((lec) => {
                    if (lec.plannedTopicIds && lec.plannedTopicIds.includes(sub.subTopicId)) {
                      // console.log('lec.plannedTopicIds', lec.plannedTopicIds);
                      // console.log('lec.coveredTopicIds', lec.coveredTopicIds);
                      obj.lecForPlanning.push(lec)
                      obj.lectureDetailsForPlanning.push(`${lec.startTime.split('T')[0]} (${this.convertUTCtoLocalTimeWithAMPM(lec.startTime)} to ${this.convertUTCtoLocalTimeWithAMPM(lec.endTime)})`)
                    }
                    if (lec.coveredTopicIds && lec.coveredTopicIds.includes(sub.subTopicId)) {
                      obj.lecForCovering.push(lec)
                      obj.lectureDetailsForCovering.push(`${lec.startTime.split('T')[0]} (${this.convertUTCtoLocalTimeWithAMPM(lec.startTime)} to ${this.convertUTCtoLocalTimeWithAMPM(lec.endTime)})`)
                    }
                  })
                }
                obj.subTopicStartDate = obj.lectureDetailsForPlanning.length > 0 ? obj.lectureDetailsForPlanning[0] : ''
                obj.subTopicEndDate = obj.lectureDetailsForCovering.length > 0 ? obj.lectureDetailsForCovering[0] : ''
                // console.log('obj', obj);
                this.arrForTopicCoverage.push(obj)
              })
            }
          })
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    async downloadTemplate() {
      // try {
      //   const pdfBlob = await this.topicRepositoryInstance.generateTopicReport(this.selectedSubject)
      //   const pdfBlob2 = new Blob([pdfBlob.data], { type: 'application/pdf' })
      //   const url = window.URL.createObjectURL(pdfBlob2)
      //   // console.log('url', url);
      //   const link = document.createElement('a')
      //   link.href = url
      //   link.setAttribute('download', 'Topic Report.xlsx')
      //   document.body.appendChild(link)
      //   link.click()
      //   // const res = await this.topicRepositoryInstance.deleteTopicReport(this.selectedSubject)
      // } catch (err) {
      //   // console.log(err.stack)
      //   window.alert('Something went wrong while downloading the excel.')
      // }
      const data = []
      if (this.arrForTopicCoverage.length > 0) {
        this.arrForTopicCoverage.forEach((item) => {
          data.push({
            "Topic Name": item.topicName,
            "Sub Topic Name": item.subTopicName,
            "Subtopic Start Date": item.subTopicStartDate,
            "Subtopic End Date": item.subTopicEndDate
          })
        })
      }
      createExcelThroughJson(data, 'Topic completion Details')
    },
    async startAddingTopic() {
      this.openModal();
      this.isTopicSelected = false
      this.updatingTopic = false
      this.createLecturesList = []
      this.selectedTopic = {}
      this.selectedTopic.lectures = []
      this.dates = []
      this.addingTopic = true
    },
    async editTopic() {
      this.updatingTopic = true
      this.editbtn = false
      this.editClicked = true;
      this.openModal();
    },
    async datesChanged(e) {
      this.processingDate = true
      let addedDates = []
      if (this.addingTopic !== true) {
        this.datesBackup = e
      }
      addedDates = e.filter((v, i, a) => a.indexOf(v) === i);

      if (this.updatingTopic === true) {
        this.editbtn = false
        this.selectedTopic.lectures = []
      }
      this.createLecturesList = []
      for (let i = 0; i < addedDates.length; i++) {
        const objToPush = {
          instituteId: this.userData.instituteId,
          semId: this.userData.semId,
          uId: this.userData.uId,
          department: this.selectedSubject.department,
          courseYear: this.selectedSubject.courseYear,
          // division: this.selectedSubject.division,
          // batch: this.selectedSubject.batch,
          dateOfLecturesNeeded: new Date(addedDates[i]).toISOString()
        }
        try {
          let timetable = await this.lectureRepositoryInstance.getLecturesForADayForAFaculty(
            objToPush
          )
          if (this.prop_type === "Division") {
            let onlyDivisionMaterial = []
            if (timetable.length > 0) {
              onlyDivisionMaterial = timetable.filter((data) => data.division === this.prop_subject.division && (data.batch === undefined || data.batch === ''))
              timetable = onlyDivisionMaterial
            }
          }
          if (this.prop_type === "Batch") {
            let onlyDivisionMaterial = []
            if (timetable.length > 0) {
              onlyDivisionMaterial = timetable.filter((data) => data.division === this.prop_subject.division && data.batch === this.prop_subject.batch)
              timetable = onlyDivisionMaterial
            }
          }
          // timetable = timetable.filter(
          //   tSlot =>
          //     tSlot.department === this.selectedSubject.department &&
          //     tSlot.courseYear === this.selectedSubject.courseYear &&
          //     tSlot.division === this.selectedSubject.division &&
          //     tSlot.batch === this.selectedSubject.batch &&
          //     tSlot.subjectId === this.selectedSubject.subjectId
          // )
          timetable.forEach(tSlot => {
            tSlot.date = formatToSlashSepearated(tSlot.dateTime.slice(0, 10))
            tSlot.time =
              timeStringConverter(tSlot.startTime) +
              ' - ' +
              timeStringConverter(tSlot.endTime)
            this.createLecturesList.push(tSlot)
          })
        } catch (err) {
          console.log(err)
        }
      }
      this.selectedTopic.lectures = this.createLecturesList
      this.selectedTopic.lectures.map((data) => {
        const localStartTime = convertUTCDateToLocalDate(data.startTime);
        const localEndTime = convertUTCDateToLocalDate(data.endTime)

        data['lectureTiming'] = localStartTime + '-' + localEndTime
      })
      if (this.updatingTopic === true) {
        this.selectedTopic.lectureCount = this.createLecturesList.length
      }
      this.processingDate = false
    },
    convertToISOString(date) {
      try {
        const convertedDate = new Date(date.valueOf());
        // IST is 5.30 hours ahead of UTC
        // convertedDate.setHours(5, 30, 0, 0);
        return convertedDate.toISOString();
      } catch (err) {
        console.log(err);
        return undefined;
      }
    },

    formatToSlashSepearated(item) {
      return formatToSlashSepearated(item)
    },
    formatToSlashSepearatedDate(item) {
      return formatToSlashSepearatedDate(item)
    },
    timeStringConverter(item) {
      return timeStringConverter(item)
    },
    async selectTopic(e) {
      this.openModal();
      if (this.editClicked === true) {
        this.editbtn = false
        this.updatingTopic = true
      } else {
        this.editbtn = true
        this.updatingTopic = false
      }
      const selectedTopic = []
      this.selectedTopic = {}
      this.dates = []
      if (e.lectureId) {
        const newLecturesList = []
        for (const [key, value] of Object.entries(this.topicsObject)) {
          if (key === e.topicId) {
            value.map((obj) => {
              selectedTopic.push(obj.lectureId)
            })
          }
        }
        this.lecturesList.filter((lecture) => {
          if (selectedTopic.includes(lecture.lectureId)) {
            newLecturesList.push(lecture)
          }
        })
        for (const [key, value] of Object.entries(this.topicsObject)) {
          if (key === e.topicId) {
            newLecturesList.map((lecture) => {
              value.map((val) => {
                if (lecture.lectureId === val.lectureId) {
                  lecture.isImplemented = val.isImplemented
                }
              })
            })
          }
        }
        this.selectedTopic.conductedCount = 0
        this.selectedTopic.lectureCount = 0
        this.selectedTopic.implementedCount = 0
        this.selectedTopic.topicId = e.topicId
        this.selectedTopic.topicName = e.topicName
        newLecturesList.map((obj) => {
          this.dates.push(obj.dateTime.slice(0, 10))
          obj.date = obj.dateTime.slice(0, 10)
          obj.time = obj.dateTime.slice(11, 16)
          this.selectedTopic.isImplemented = obj.isImplemented
          if (obj.isConducted === true) {
            this.selectedTopic.conductedCount++
          }
          if (obj.isImplemented === true) {
            this.selectedTopic.implementedCount++
          }
        })
        this.selectedTopic.lectureCount = newLecturesList.length
        this.selectedTopic['dates'] = this.dates
        this.selectedTopic.lectures = newLecturesList
      } else {
        this.updatingTopic = true
        this.addingTopic = false
        this.selectedTopic = {}
        this.selectedTopic.conductedCount = 0
        this.selectedTopic.lectureCount = 0
        this.selectedTopic.implementedCount = 0
        this.selectedTopic.topicId = e.topicId
        this.selectedTopic.topicName = e.topicName
        this.selectedTopic.isImplemented = e.isImplemented
        this.dates = []
        this.selectedTopic['dates'] = []
        this.selectedTopic.lectures = []
      }
      for (const [key, value] of Object.entries(this.topicsObject)) {
        // console.log("this.topic ojedy", key, value)
        if (key === e.topicId) {
          value.map((obj) => {
            this.selectedTopic['cos'] = obj.cos
            this.selectedTopic['blooms'] = obj.blooms
          })
        }
      }
      e.isSelected = true
      this.addingTopic = false
      // Call API to check if Topic Feedback is launched
      this.isFeedbackLaunched = false
      this.isTopicSelected = true
      this.selectedBlooms = this.selectedTopic.blooms;
      this.selectedCos = this.selectedTopic.cos;
    },
    async deleteTopic(topic) {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          topicId: topic.topicId
        }
        await this.topicRepositoryInstance.deleteTopic(objToPush)
        this.fetchData()
      } catch (err) {
        console.log(err)
      }
      this.closeModal()
    },
    async updateTopic(e) {
      this.editbtn = false
      const lectureIds = []
      const objToPush = {
        instituteId: this.selectedSubject.instituteId,
        semId: this.selectedSubject.semId,
        department: this.selectedSubject.department,
        courseYear: this.selectedSubject.courseYear,
        // division: this.selectedSubject.division,
        // batch: this.selectedSubject.batch,
        subjectId: this.selectedSubject.subjectId,
        topicName: e.topicName,
        topicId: e.topicId,
        cos: this.selectedCos || [],
        blooms: this.selectedBlooms || []
      }
      if (this.selection.length > 0) {
        this.selection.map((obj) => {
          lectureIds.push(obj.lectureId)
        })
        objToPush['lectureIds'] = lectureIds
      }
      if (this.prop_type === "Division") {
        objToPush.division = this.selectedSubject.division
      }
      if (this.prop_type === "Batch") {
        objToPush.division = this.selectedSubject.division
        objToPush.batch = this.selectedSubject.batch
      }
      const res = await this.topicRepositoryInstance.updateTopic(objToPush)
      console.log('res', res);
      this.selectedTopic.lectures = []
      this.updatingTopic = false
      this.fetchData()
      this.closeModal();
    },
    async createTopic() {
      try {
        if (this.tempTopicName === '') {
          alert('Please enter topic name')
          return
        }
        this.updatingTopic = false
        this.editbtn = false
        let lectureIds = []
        const objToPush = {
          instituteId: this.selectedSubject.instituteId,
          semId: this.selectedSubject.semId,
          department: this.selectedSubject.department,
          courseYear: this.selectedSubject.courseYear,
          // batch: this.selectedSubject.batch,
          subjectId: this.selectedSubject.subjectId,
          topicName: this.tempTopicName,
          cos: this.selectedCos,
          blooms: this.selectedBlooms
        }
        if (this.prop_type === "Group") {
          objToPush.assignedGroupForSubject = {
            groupName: this.prop_groupInfo.groupName,
            groupId: this.prop_groupInfo.groupId
          }
          objToPush.division = ""
        }
        if (this.prop_type === "Division") {
          objToPush.division = this.selectedSubject.division
        }
        if (this.prop_type === "Batch") {
          objToPush.division = this.selectedSubject.division
          objToPush.batch = this.selectedSubject.batch
        }
        if (this.selection.length > 0) {
          // console.log('this.selection', this.selection);
          lectureIds = this.selection.map(item => item.lectureId)
          objToPush['lectureIds'] = lectureIds
          // objToPush['endTime'] = item.endTime
        }
        await this.topicRepositoryInstance.createTopic(objToPush)
        this.tempTopicName = ''
        this.dates = []
        this.createLecturesList = []
        this.selection = []
        this.isTopicSelected = false
        this.fetchData()
        this.closeModal()
      } catch (err) {
        console.log(err)
      }
    },
    async markAsImplemented(item, isImplemented) {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          topicId: this.selectedTopic.topicId,
          lectureId: item.lectureId
        }
        if (item.isImplemented === true) {
          this.isImplementedCountNumber++;
          await this.topicRepositoryInstance.markAsImplemented(objToPush)
        } else {
          this.isImplementedCountNumber--;
          await this.topicRepositoryInstance.markAsUnimplemented(objToPush)
        }
        this.fetchData()
      } catch (err) {
        console.log(err)
      }
      this.closeModal();
    },
    async fetchStudentFeedback() {
      // Call Api to fetch students feedback for the topic
      //let objToPush = JSON.parse(JSON.stringify(this.selectedTopic))
      this.topicFeedbackQuestions = []
      this.showStudentFeedback = true
    },
    async launchStudentFeedback() {
      // Call Api to lauch students feedback for the topic
      //  let objToPush = JSON.parse(JSON.stringify(this.selectedTopic))
    },
    goToTopicFeedback() {
      this.$router.push({
        name: 'activityTopicFeedback',
        params: {
          prop_subject: this.prop_subject
        }
      })
    },
    goToFeedbackForm() {
      this.$router.push({
        name: 'activityFeedbackForm',
        params: {
          prop_subject: this.prop_subject,
          prop_topicsList: this.topicsList
        }
      })
    },
    // goToTopicFeedbackTemplate () {
    //   this.$router.push({
    //     name: 'activityTopicFeedbackTemplate'
    //   })
    // },
    convertDate(date) {
      return convertDate(date)
    },
    cancelCreating() {
      this.closeModal();
      // this.modalVisible = false
      this.tempTopicName = ''
    }
  }
}
</script>

<style src="./activityTopics.css"></style>
