<template>
  <div :style="inputWrapperStyle">
    <v-menu v-model="menu" :offset-y="true" :close-on-content-click="false">
      <template v-slot:activator="{ on }">
        <!-- The CustomTextField component will be used later once it is fully compatible. -->
        <!-- @click is being used temporarily because v-on is not yet fully functional with the CustomTextField component. -->
        <!-- <CustomTextField
        clearable
        v-on="on"
        :value="transformedValue"
        @click="menu = true"
        readonly
        appendIcon="clockOutline"
        placeholder="Select Time"></CustomTextField> -->
        <v-text-field @click:clear="clearValue" clearable append-icon="mdi-clock-outline" :value="transformedValue" solo
          flat background-color='#E7E7FF' placeholder="Select Time" v-on="on" readonly />
      </template>
      <v-card style="padding: 20px">
        <section style="display: flex; gap: 5px; justify-content: space-around">
          <div style="border: 1px solid #a8a8a8; border-radius: 4px; padding: 5px">
            <div style="text-align: center; padding: 2px">
              <div style="font-size: 16px; font-weight: 600">Hour</div>
            </div>
            <v-list class="scrollable-list">
              <v-list-item-group v-model="selectedHours" color="#050D92">
                <v-list-item v-for="(hour, index) in hours" :key="index + 'h'" :value="hour" @click="onSelectMinute">
                  <v-list-item-content>
                    <v-list-item-title>{{ hour }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
          <div style="border: 1px solid #a8a8a8; border-radius: 4px; padding: 5px">
            <div style="text-align: center; padding: 2px">
              <div style="font-size: 16px; font-weight: 600">Minute</div>
            </div>
            <v-list class="scrollable-list" ref="minuteList">
              <v-list-item-group v-model="selectedMinutes" color="#050D92">
                <v-list-item v-for="(minute, index) in minutes" :key="index + 'm'" :value="minute"
                  :ref="'minuteContent' + index">
                  <v-list-item-content>
                    <v-list-item-title>{{ minute }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
          <div style="border: 1px solid #a8a8a8; border-radius: 4px; padding: 5px">
            <div style="text-align: center; padding: 2px">
              <div style="font-size: 16px; font-weight: 600">AM/PM</div>
            </div>
            <v-list class="scrollable-list">
              <v-list-item-group v-model="selectedPeriods" color="#050D92">
                <v-list-item v-for="(period, index) in periods" :key="index + 's'" :value="period">
                  <v-list-item-content>
                    <v-list-item-title>{{ period }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </section>
        <div style="margin-top: 10px; text-align: center">
          <v-btn small :disabled="selectedHours === ''|| selectedMinutes === '' || selectedPeriods === '' || selectedPeriods === undefined || selectedMinutes === undefined || selectedHours === undefined" style="color: white;" color="#FF4F1F" @click="confirmSelection">Save</v-btn>
          <v-btn small style="margin-left: 10px" outlined color="#FF4F1F" @click="cancelSelection">Clear</v-btn>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import { timeStringConverterV2 } from "../../../Services/Utils/DateTimeFormatter";
import { minutesArray, hoursArray } from "../../../Constants/Utils/Statics";
// import CustomTextField from "../TextField.vue";
export default {
  name: "CustomTimePicker",
  // components: {
  //   CustomTextField
  // },
  props: {
    width: {
      type: [String, Number],
      default: "100%"
    },
    value: { // Change this to value for v-model
      type: String,
      default: "22_30"
    }
  },
  data() {
    return {
      // These are the formats being transformed into the current format i.e. '01:23 AM'.
      // For any other new pattern, modify the 'transformedValue' method.
      // 2024-10-23T13:30:00.000Z
      // 2024-10-23T01:30:00.000Z
      // 2024-10-23T06:40:20Z
      // 03:30
      // 03_30
      menu: false, // Control the menu visibility
      selectedHours: "",
      selectedMinutes: "",
      selectedPeriods: "",
      hours: hoursArray,
      minutes: minutesArray,
      periods: ["AM", "PM"],
      duration: 300,
      offset: 0,
      type: "number"
    };
  },
  computed: {
    currentTime: {
      get() {
        return this.value;
      },
      set(newValue) {
        if (newValue !== this.value) {
          this.$emit('input', newValue);
        }
      }
    },
    transformedValue() {
      let value = this.currentTime;
      const regex = /^(0[1-9]|1[0-2]):([0-5][0-9]) (AM|PM)$/; //check if the value is in the desired format i.e. '01:23 AM'
      if (!regex.test(this.currentTime)) {
        value = timeStringConverterV2(this.currentTime).timeString;
      }
      return value;
    },
    inputWrapperStyle() {
      const tempWidth =
        typeof this.width === "number" ? `${this.width}px` : this.width;
      const styleObj = {
        width: tempWidth
      };
      return styleObj;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.currentTime = newValue;
      }
    }
  },
  methods: {
    clearValue() {
      this.$emit("input", '');
    },
    onSelectMinute() {
      // You can place any additional logic here if needed.
      this.scrollToHalfOfMinutes();
    },
    confirmSelection() {
      const hours = this.selectedHours || "01";
      const minutes = this.selectedMinutes || "01";
      const periods = this.selectedPeriods || "AM";

      const formattedTime = `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")} ${periods}`;

      this.menu = false;
      this.$emit("input", formattedTime);
    },
    cancelSelection() {
      this.selectedHours = "";
      this.selectedMinutes = "";
      this.selectedPeriods = "";
      this.menu = false;
      this.$emit("input", "");
    },
    scrollToHalfOfMinutes() {
      const minuteList = this.$refs.minuteList.$el;
      const halfIndex = Math.floor(this.minutes.length / 2);
      const targetElementArray = this.$refs[`minuteContent${halfIndex}`];
      if (targetElementArray && targetElementArray[0]) {
        if (
          this.selectedMinutes === "" ||
          this.selectedMinutes === undefined ||
          this.selectedMinutes === null
        ) {
          minuteList.scrollTop = (minuteList.clientHeight * 14) / 2;
        }
      } else {
        console.warn("Target element not found.");
      }
    }
  }

};
</script>
<style scoped>
.scrollable-list {
  height: 200px;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.menu-card {
  width: 320px;
  max-width: 100%;
  padding: 16px;
}

.scrollable-list::-webkit-scrollbar {
  width: 2px;
}

.scrollable-list::-webkit-scrollbar-track {
  background: transparent;
}

.scrollable-list::-webkit-scrollbar-thumb {
  background: #898888;
}
</style>
