import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/lectures'

class LectureRepository {
  constructor(context) {
    this.context = context
  }

  async getPreviousLecForFacForDivForSub(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getPreviousLecForFacForDivForSub, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getAllLecturesOfMultipleInstitutesPerDayByUId(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAllLecturesOfMultipleInstitutesPerDayByUId, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async updateMultipleLectureDocument(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.updateMultipleLectureDocument, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async createMultipleLectures(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createMultipleLectures, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async arrangeALecture(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.arrangeALecture, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

  async lectureNotification(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.lectureNotification, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

  async getLecture(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLecture, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lecture
  }

  async getLectureForAnyCriteriaForSpecificDateRange(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLectureForAnyCriteriaForSpecificDateRange, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

  async facultyLectureClashDetection(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.facultyLectureClashDetection, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

  async roomClashDetection(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.roomClashDetection, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

  async getAllLectureOfADivision(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAllLectureOfADivision, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async deleteLecture(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.deleteRequest(ApiNames.deleteLecture, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lecture
  }

  async getLecturesBySubject(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getLecturesBySubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lecture
  }

  async getLecturesOfADivisionForADay(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLecturesOfADivisionForADay, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

  async getLecturesForDateForAssignedSubjects(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLecturesForDateForAssignedSubjects, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getLecturesOfAFacultyOfASubject(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLecturesOfAFacultyOfASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

  async getLecturesOfAFacultyOfASubjectOfADivision(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLecturesOfAFacultyOfASubjectOfADivision, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

  async getLecturesForADay(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLecturesForADay, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

  async getFreeFacultiesForALecture(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getFreeFacultiesForALecture, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.freeFaculties
  }

  async saveAttendance(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.saveAttendance, data)
    this.context.$store.commit('liveData/set_loading', false)
    return { present: response.present, absent: response.absent, dateTime: response.dateTime, status: response.status }
  }

  async getTotalAttendedLecturesForADivisionForSubject(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTotalAttendedLecturesForADivisionForSubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.studentsList
  }

  async getAttendanceAnalysisForADivision(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAttendanceAnalysisForADivision, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.attendanceAnalysis
  }

  async getConductedLecturesCountOfASubject(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getConductedLecturesCountOfASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.conductedLectures
  }

  async getConductedLecturesOfASubject(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getConductedLecturesOfASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.conductedLectures
  }

  async getAttendanceAnalysisForADivisionForASubject(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAttendanceAnalysisForADivisionForASubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async cancelALecture(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.cancelALecture, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectureStatus
  }

  async updateRemarksForLecture(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateRemarksForLecture, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.remarksForLecture
  }

  async getLecturesForADayForAFaculty(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLecturesForADayForAFaculty, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

  async generateExcelAttendanceAnalysisDivisionWise(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.downloadFile(ApiNames.generateExcelAttendanceAnalysisDivisionWise, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async generateExcelSubjectWise(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.downloadFile(ApiNames.generateExcelSubjectWise, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async generateAttendanceAnalysisExcel(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.downloadFile(ApiNames.generateAttendanceAnalysisExcel, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async generateCumulativeAttendanceExcel(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.generateCumulativeAttendanceExcel, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async updateLecturerById(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.updateLecturerById, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async updateLectureRecordingLinkById(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.updateLectureRecordingLinkById, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async generateAttendanceSheetExcel(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.downloadFile(ApiNames.generateAttendanceSheetExcel, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async generateExcelForCreateLectureAndAttendance(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.downloadFile(ApiNames.generateExcelForCreateLectureAndAttendance, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async downloadCumulativeAttendance(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.downloadFile(ApiNames.generateAttendanceAnalysisExcel, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getSignedUrl(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.getSignedUrl,
      data,
      { "Content-Type": "application/json" }
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }
}

export default LectureRepository
